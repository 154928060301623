/*--------------------------------------------------------*\
	Factor Loops on Driver Landing Pages
\*--------------------------------------------------------*/

.block--print {
	margin-top: -2.5rem;
	& + .block {
		padding: 2.5rem 0 5rem;
	}
}
.printfriendly {
	text-align: left;
	img {
		// margin-left: auto;
	}
}

.block--drivers {
	&:nth-child(even) {
		background-color: $grey--light;
	}

	.driver__title {
		margin-bottom: 2rem;
		.small {
			font-size: 1rem;
			vertical-align: middle;
			margin-left: 2rem;
		}
	}

	.driver__description {

		@include p();
		margin-bottom: 3rem;
		line-height: 1.35;
		max-width: 40rem;
	}

	.score__container {

		position: relative;
		width: 100%;
		max-width: 40rem;
		height: 2rem;
		background-color: $grey;
		margin-bottom: 3rem;
	
		.score {
			position: absolute;
			height: 100%;
			top: 0;
			left: 0;
			width: 0;
			transition: width 0.5s ease;
		}

		.goal {
			height: 100%;
			border-left: 2px solid;

			span { 
				@include p;
				@include md(0.875rem, 1.5);
				text-transform: uppercase;
				position: absolute;
				top: 100%;
				transform: translateX(-50%);
				@include m(400) {
					font-size: 0.75rem;
				}
			}

		}
		.goal--none {
			display: none;
		}
	}



	.factors__title {
		margin-bottom: 2rem; 
		font-size: 2rem;
	}

	.factors__post { 
		margin-bottom: 2rem;
		position: relative;
		@include m(575) {
			margin-bottom: 3rem;
		}
	}

	.factor__container {
		@include flexContainer(row,nowrap);
		position: relative;

		@include m(575) {
			@include flexContainer(row, wrap)
		}

		.score--dots {
			@include flex(1,0,5rem);
			width: 5rem;

			@include m(575) {
				@include flex(1,0,2rem);
				width: 2rem;

			}
			@include m(400) {
				flex-basis: 100%;
				width: 100%;
			}
		}

		.factor__link {
			
			@inlude m(575) {
				@include flex(1,0,100%);
				width: 100%;
				max-width: 100%;
			}
		}

		.factor__content {

			@include flex(1,0,calc(100% - 7rem));
			width: calc(100% - 7rem);
			max-width: 100%;
			padding: 0 3rem 0 0.5rem;
			.snippet { max-width: 50rem;}
			@include m(575) {
				@include flex(1,0,calc(100% - 5rem));
				width: calc(100% - 5rem);
			}
			@include m(400) {
				flex-basis: 100%;
				width: 100%;
				margin-bottom: 1.5rem;
			}
		}
	}

	.factor__link {
		@include flexContainer(row);
		@include flex(1,0,2rem);
		width: 2rem;
		max-width: 2rem;
		position: relative;
		align-items: center;
		
		svg {

			width: 1.75rem;
			height:1.75rem;

			path { 
				transition: fill 0.25s ease;
				fill: $orange; 
			}
		}

		&--jobs {
			svg {
				path { fill: $teal; }
			}
		}
		&--health {
			svg {
				path { fill: $blue; }
			}
		}
		&--quality-of-place {
			svg {
				path { fill: $purple; }
			}
		}
	}
	
	.factor__container:hover .factor__link { 
		svg {
			path { fill: $blue--slate; }
		}
		&--health {
			svg {
				path { fill: $orange; }
			}
		}
	}
}