/*--------------------------------------------------------*\
	Contact Module
\*--------------------------------------------------------*/
.block--contact {
	.address {
		padding-left: 0;
		margin-bottom: 0;
		list-style: none;
	}

	.phone-number, 
	.email {
		display: block;
		margin-bottom: 1.25rem;	
	}
	.email { margin-bottom: 1.5rem; }

	.map__container {
		width: 100%;
		height: 100%;
		position: relative;
		&:after { 
			content: "";
			display: block;
			padding-bottom: 66.6%;
		}

		iframe {
			top: 0;
			left: 0;
			position: absolute;
			width: 100%;
			height: 100%;
		}
	}
}