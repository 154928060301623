	/*--------------------------------------------------------*\
	Tabs Module
\*--------------------------------------------------------*/
.column__container--tabs-1 {
	width: 100%;
	// margin: 0 auto;
	justify-content: center;
	.slider__item {
		width: 100%;
	}
}
.tabs {

	@include flexContainer(row, nowrap);
	width: 100%;
	align-items: flex-start;

	@include m(1000) {
		@include flexContainer(column);
	}
	@include m(800) {
		width: 100%;
	}
	

	
	

	.tabs__list {
		@include flex(0,1,calc((100% / 3) - 4rem));
		border-right: 2px solid $body;
		height: 340px;
		width: calc((100% / 3) - 4rem);
		max-width: 100%;
		margin-right: 4rem;
		position: relative;

		@include flexContainer(column, nowrap);

		@include m(1000){
			width: 100%;
			flex-grow: 0;
			flex-basis: auto;
			height: auto;
			margin-bottom: 2rem;
			border-right: 0;
			border-bottom: 2px solid $body;
			margin-right: 0;

			@include flexContainer(row, nowrap);
		}

		@include m(600) {
			border-bottom: 0;
		}

		.tab__arrow {
			position: absolute;
			height: 32px;
			right: 0;
			top: 0;
			transform: translateY(-50%);
			opacity: 0;
			transition: top 0.2s ease,
				opacity 0.5s ease,
				left 0.2s ease;

			@include m(1000) {
				right: initial;
				left: 0;
				top: 100%;
				width: 32px;
				transform: translate(-50%, 0);

			}
			@include m(600) {
				display: none;
			}

			&:before {
				position: absolute;
				left: 0;
				content: "";
				display: block;
				border-bottom: 1rem solid transparent;
				border-left: 1rem solid $body;
				border-right: 1rem solid transparent;  
				border-top: 1rem solid transparent;

				@include m(1000) {
					border-top-color: $body;
					border-left-color: transparent;
					top: 0;
				}
			}
			&:after {
				position: absolute;
				left: 0;
				top: 0.2rem;
				content: "";
				display: block;
				border-bottom: 0.85rem solid transparent;
				border-left: 0.85rem solid white;
				border-right: 0.85rem solid transparent;  
				border-top: 0.85rem solid transparent;
				@include m(1000) {
					border-top-color: white;
					border-left-color: transparent;
					top: 0;
					border-bottom: 0.9rem solid transparent;
					border-left: 0.9rem solid transparent;
					border-right: 0.9rem solid transparent;  
					border-top: 0.9rem solid white;
					left: 0.115rem;
				}
			}
		}
	}

	.tabs__content {
		@include flex(1,0,calc(200% / 3));
		width: calc(200% / 3);
		max-width: 100%;


		@include clearfix();
		@include m(1000) {
			width: 100%;
		}
	}

	.tab {
		@include flex(1,1,auto);
		@include rg(1rem, 1);
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-weight: 500;
		text-transform: uppercase;
		height: auto;
		cursor: pointer;

		@include m(1000) {
			width: 25%;
			height: auto;
			//display: inline-block;
			text-align: center;
			justify-content: center;
			padding-bottom: 0.5rem;
		}

		@include m(600) {
			width: 100%;
			display: block;
			padding: 0.5rem 0;

		}

		&:hover,
		&.active {
			color: $blue--slate;
		}
	}

	.tabs__content {
		overflow: hidden;
		position: relative;
		height: 385px;
		min-height: 385px; // Thanks, Safari

		@include m(414) {
			height: 354px;
			min-height: 354px;
		}

		.chart__container {
			width: 100%;
			opacity: 0;
			visibility:hidden;
			transition: opacity 0.4s ease-in-out;
			height: 352px;

			&:active, &:focus { outline: none; }

			&.show {
				visibility: visible;
				opacity: 1;
				display: initial!important;
			}
		}

		.slider--charts {
			width: 100%;
			height: 352px;
			//min-height: 352px; // Thanks, Safari
		
		}

		.slider__item--charts {
			width: 100%;

			&:first-child, &:only-child {
				display: initial;
			}
		}

		img {
			height: 350px;
			width: 525px;
		}
	}

	@media (min-width: 600px) {
		.slider__item img {
			max-width: 525px;
		}
	}

	.flickity-viewport { 
		height: 100%; 
	}
	
	.flickity-page-dots {
		height: 32px;
		padding-left: 0;

		.dot {
			height: 1rem;
			width: 1rem;
			background-color: $grey;
			display: inline-block;
			border-radius: 100%;
			transition: background-color 0.25s ease;

			&:not(:last-child) { 
				margin-right: 0.5rem;
			}

			&.is-selected {
				background-color: $blue;
			}
		}

	 }
}