/*--------------------------------------------------------*\
	Block ==> Mixins to Share
\*--------------------------------------------------------*/

@mixin blockPadding() {
	padding: 5rem 0;
	@include m(768) {
		padding: 3.5rem 0;
	}
	@include m(640) {
		padding: 3rem 0;
	}
	@include m(480) {
		padding: 2rem 0;
	}
}

/*--------------------------------------------------------*\
	Block
\*--------------------------------------------------------*/
.block {
	width: 100%;
	position: relative;

	@include bg-center;	
	@include blockPadding();

	.wrapper--about & {
		padding: 7rem 0;
		@include m(768) {
			padding: 5rem 0;
		}
		@include m(640) {
			padding: 3rem 0;
		}
		@include m(480) {
			padding: 2rem 0;
		}
	}
	
	border-left: 1rem solid white;
	border-right: 1rem solid white;

	&__container {
		@include container();

		&--full {
			@include container('full');
		}

		&--large {
			@include container('large');
		}
		&--medium {
			@include container('medium');
		}
		
		&--small {
			@include container('small');
		}
	}

	&--content {
		&-center {
			text-align: center;
		}
		&-left {
			text-align: left;
		}
		&-right {
			text-align: right;
		}
	}
}
.print__container {
	a { 
		font-size: 0.75rem;
		font-weight: 400;
		&:hover {
			color: $orange!important;
		}
	}
	margin-bottom: 1.5rem;
}
.printfriendly {
	vertical-align: middle;
	display: inline-block;
}
.bg--image {
	color: white;
	margin-bottom: 1rem;
}
.bg--yellow .content {
	color: $body;
}