/*--------------------------------------------------------*\
	Team Module
\*--------------------------------------------------------*/
.block--team {
	h1 { margin-bottom: 5rem; }
	.team__container {
		@include flexContainer(row,wrap);
		justify-content: center;
		position: relative;
	}

	.teamItem__container {
		margin: 0 auto;
	}

	.team__image {
		margin-bottom: 1.375rem;
	}

	.team__item { 
		text-align: left;
		width: calc( (100% / 3) - (3/4 * 2rem) );
		display: flex;
		justify-content: center;
		position: relative;
		&:not(:last-child) { margin-right: 2rem; }
		.modal__trigger { cursor: pointer; }

		@include m(700) {
			width: 100%;
			margin-bottom: 2rem;
			text-align: center;
			&:not(:last-child) {
				margin-right: 0;
			}
		}
	}

	.team__name {
		font-size: 1.35rem;
		color: $teal;
		display: block;
	}
}

.modal--team {
	border:1x solid #898E94;
	padding: 4rem;
	@include m(480) {
		padding: 2rem;
	}
	.modal__close {
		&::before, &::after {
			background-color: $teal;
		}
	}
	.team__name { font-size: 1.375rem; color: $teal; }
	.team__title { @include p(); }

	.modal__footer--social {
		margin-top: 1.5rem;
		svg {
			height: 1.5rem;
			width: 1.5rem;
			path { 
				fill: $teal;
			}
		}
	}
}