/*--------------------------------------------------------*\
	Partners Module
\*--------------------------------------------------------*/
.block--partners {
	padding-top: 0;
	
	.partners__container {
		@include flexContainer(row, wrap);
		width: 100%;

		@include m(500) {
			justify-content: center;
		}
	}

	.partners__item {
		
		@include flex(1,0,auto);
		border: 1px solid $blue;
		position: relative;
		margin-bottom: 2rem;

		&:after {
			content: "";
			display: block;
			padding-bottom: 100%;
		}
		@media (min-width: 901px) {
			flex-basis: calc(25% - (3/4 * 2rem));
			width: calc(25% - (3/4 * 2rem));
			&:not(:nth-child(4n)) { margin-right: 2rem; }

			.browser--ie & {
				flex-basis: calc(25% - (3/4 * 2rem) - 1px);
				width: calc(25% - (3/4 * 2rem) - 1px);
			}
		}
		@include m(900, 641) {
			width: calc((100% / 3) - (2/3 * 2rem));
			flex-basis: calc((100% / 3) - (2/3 * 2rem));
			&:not(:nth-child(3n)) { margin-right: 2rem; }

			.browser--ie & {
				flex-basis: calc((100% / 3) - (2/3 * 2rem) - 1px);
				width: calc((100% / 3) - (2/3 * 2rem) - 1px);
			}
		}

		@include m(640, 501) {
			width: calc(50% - 1rem);
			flex-basis: calc(50% - 1rem);
			&:not(:nth-child(2n)) { margin-right: 2rem; }
		}

		@include m(500, 381) {
			width: 75%;
			flex-basis: 75%;
		}
		@include m(380) {
			width: 100%;
			flex-basis: 100%;
		}
	}

	.logo__container {
		@include flexContainer(row);
		justify-content: center;
		align-items: center;

		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%; 
	}
}