svg path {transition: fill 0.25s ease; }
.social__list, .content .social__list {
	display: inline-block;
	padding-left: 0;

	.social__item {
		position: relative;
		display: inline-block;
		&:not(:last-child) { margin-right: 1rem; }

		svg {
			width: 2rem;
			height: 2rem;
			
			.social-share & {
				width: 1.5rem;
				height: 1.5rem;
				path {
					fill: $blue--slate;
				}
			}

			.block--contact & {
				width: 1.5rem;
				height: 1.5rem;
				path {
					fill: $teal;
				}
			}


			.footer & {
				path {
					fill: white;
				}
			}
		}


		&:hover {
			.footer &,
			.modal--team &,
			.block--contact & {
				svg {
					path { fill: $orange; }
				}
			}

			.wrapper--single--blog .social-share & { 
				path { fill: $orange; }
			}
			.wrapper--single--news .social-share & { 
				path { fill: $orange; }
			}
			.wrapper--single--press .social-share & { 
				path { fill: $purple; }
			}
			.wrapper--single--events .social-share & { 
				path { fill: $green; }
			}
		}
	}
}

.social-share { 
	text-align: right;
	border-bottom: 1px solid;
	padding: 1rem 0;
	color: $blue--slate;
	span {vertical-align: middle;}

	@include m(600) {
		text-align: left;
	}

	.social__list {
		vertical-align: middle;
		padding-left: 1rem;
		height: 1.5rem;
	}
	.social__item {
		width: 1.5rem;
		height: 1.5rem;
	}
}

