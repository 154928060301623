/*--------------------------------------------------------*\
	Positioning
\*--------------------------------------------------------*/

$timing: cubic-bezier(0.190, 1.000, 0.220, 1.000);

@mixin v-center($top: 50%) {
	position: absolute;
	top: $top;
	transform: translateY(-50%);
}

@mixin h-center($left: 50%) {
	position: absolute;
	left: $left;
	transform: translateX(-50%);
}

@mixin center() {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
}

@mixin un-center() {
	position: relative;
	top: 0;
	left: 0;
	transform: translate(0,0);
}

@mixin bg-center() {
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}

@mixin clearfix {
	&::before,&::after {
		content: '';
		display: block;
		clear: both;
	}
}

@mixin cover {
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 10;
}

.cover {
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 10;
}

/*--------------------------------------------------------*\
	Block Container
\*--------------------------------------------------------*/

@mixin container($type:"") {
	width: 100%;
	padding: 0 3rem;
	margin: 0 auto;
	position: relative;
	z-index: 5;

	@if ($type == "small") {
		max-width: 55rem;
	}
	@elseif ($type == "medium") {
		max-width: 80rem;
		@include m(500) {
			padding: 0;
		}
	}
	@elseif ($type == "large") {
		max-width: calc(100% - 10rem);
		@include m(1430) {
			max-width: calc(100% - 3rem);

		}
		@include m(1200) {
			max-width: calc(100% - 1rem);
			padding: 0 1.5rem;
		}
		@include m(1024) {
			max-width: calc(100%);

		}
		@include m(540) {
			max-width: calc( 100% - 1.5rem);
		}
		@include m(480) {
			max-width: 100%;
			padding: 0 0.5rem;
		}
	}
	@elseif ($type == "full") {
		max-width: 100%;
		padding: 0!important;
	}
	@elseif ($type == "") {
		//max-width: 80rem;
		max-width: 68rem;
	}
	@else { }

	@media (max-width: 768px) {
		padding: 0 2.5rem;
	}
	@media (max-width: 640px) {
		padding: 0 2rem;
	}
	@media (max-width: 480px) {
		padding: 0 1.5rem;
	}
	@media (max-width: 360px) {
		padding: 0;
	}
}

/*--------------------------------------------------------*\
	Column Based Widths
\*--------------------------------------------------------*/

@function width($columns, $margin) {
	$width: 100% / $columns;
	$margin: $margin * (($columns - 1) / $columns);
	@return calc(#{$width} - #{$margin});
}

/*--------------------------------------------------------*\
	Flexbox
\*--------------------------------------------------------*/

@mixin flex($shrink, $grow, $basis) {
	flex-shrink: $shrink;
	flex-grow: $grow;
	flex-basis: $basis;
	max-width: 100%;
}

@mixin flexContainer($direction:row, $wrap: nowrap) {
	display: flex;
	flex-direction: $direction;
	flex-wrap: $wrap;
}

/*--------------------------------------------------------*\
	Media Query Mixin
\*--------------------------------------------------------*/
@function em($pixels) {
	@return #{$pixels / 16}em;
}

// MQ Width

@mixin m($width, $widthEnd: false) {
	@if ($widthEnd == false) {
		@if ( $width > 200 ) {
			@media (max-width: em($width)) {
				@content;
			}
		} @else {
			@media (max-width: #{$width}em) {
				@content;
			}
		}
	} @else {
		@if ( $width > 200 ) {
			@media (max-width: em($width)) and (min-width: em($widthEnd)) {
				@content;
			}
		} @else {
			@media (max-width: #{$width}em) and (min-width: #{$width}em) {
				@content;
			}
		}	
	}
}

// MQ Height
@mixin mh($height, $heightEnd: false) {
	@if ($heightEnd == false) {
		@if ( $height > 200 ) {
			@media (max-height: em($height)) {
				@content;
			}
		} @else {
			@media (max-height: #{$height}em) {
				@content;
			}
		}
	} @else {
		@if ( $height > 200 ) {
			@media (max-height: em($height)) and (min-height: em($heightEnd)) {
				@content;
			}
		} @else {
			@media (max-height: #{$height}em) and (min-height: #{$height}em) {
				@content;
			}
		}	
	}
}

/*--------------------------------------------------------*\
	String Functions
\*--------------------------------------------------------*/

@function capitalize($string) {
	@return to-upper-case(str-slice($string, 1, 1)) + str-slice($string, 2);
}