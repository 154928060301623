/*--------------------------------------------------------*\
	Colors
\*--------------------------------------------------------*/
$black: #333;
$white: #fff;
$grey: #D1D1CE;
$grey--dark: #2d4244;
$grey--light: #f8f8f8;

// Theme Colors
$body: $black;
$blue--slate: #39607A;
$blue: #0E3466;
$teal: #00ABB7;
$orange: #F37E2D;
$green: #96CA4F;
$purple: #520336;
$yellow: #FFD600;
$red: #D92632;


/*--------------------------------------------------------*\
	Backgrounds
\*--------------------------------------------------------*/

$colors:    black #222,
			blue--slate #39607A,
			blue #0E3466,
			teal #00ABB7,
			orange #F37E2D,
			grey #D1D1CE,
			grey--dark #2E4244,
			grey--light #f8f8f8,
			green #96CA4F,
			purple #520336,
			yellow #FFD600,
			white white,
			red #D92632;

@each $color in $colors {
	$color-name: nth($color, 1);
	$color-var: nth($color, 2);

	.bg--#{$color-name} {
		background-color: $color-var;

		@if ($color-name != white and $color-name != grey--light and $color-name != grey) {
			color: #fff;
			.content {
				color: #fff;
			}
		}
		@if ($color-name != white ) {
			margin-bottom: 1rem;
		}
		@if ($color-name == blue--slate) {
			.content a {
				color: $orange;
				&:hover {
					color: $teal;
				}
			}
		}
	}
}