/*--------------------------------------------------------*\
	Universals
\*--------------------------------------------------------*/

html {
	font-size: 16px;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: $body;
}

html, body { 
	height: 100%; 
}

*, *::before, *::after {
	box-sizing: inherit;
	-webkit-font-smoothing: inherit;
}

a, span.modal__trigger {
	transition: all 1s $timing;
	text-decoration: none;
	color: $blue--slate;
	&:hover {
		color: $orange;
	}
}

.wrapper {
	width: 100%;
	min-height: 100%;
	transition: opacity 0.75s ease;
	opacity: 0;
	overflow: hidden;
	&--loaded {
		opacity: 1;
	}
}

img {
	max-width: 100%;
	height: auto;
	display: block;
}