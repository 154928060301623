// Google Fonts
// @import url('https://fonts.googleapis.com/css?family=Open+Sans');

// Font Files
// @font-face {
// 	font-family:"Avenir W01";
// 	src: url("/build/fonts/0078f486-8e52-42c0-ad81-3c8d3d43f48e.woff2") format("woff2"),
// 		 url("/build/fonts/908c4810-64db-4b46-bb8e-823eb41f68c0.woff") format("woff");
// 	font-weight: 200;
// 	font-style: normal;
// }

@mixin x-lt($size, $line-height, $style: normal) {
	font-family: 'museo-sans', sans-serif;
	font-weight: 300;
	font-size: $size;
	line-height: $line-height;
	@if ($style == italic) {
		font-style: italic;
	} @else {
		font-style: normal;
	}
}

@mixin lt($size, $line-height, $style: normal) {
	font-family: 'museo-sans', sans-serif;
	font-weight: 300;
	font-size: $size;
	line-height: $line-height;
	@if ($style == italic) {
		font-style: italic;
	} @else {
		font-style: normal;
	}
}

@mixin rg($size, $line-height, $style: normal) {
	font-family: 'museo-sans', sans-serif;
	font-weight: 300;
	font-size: $size;
	line-height: $line-height;
	@if ($style == italic) {
		font-style: italic;
	} @else {
		font-style: normal;
	}
}

@mixin md($size, $line-height, $style: normal) {
	font-family: 'museo-sans', sans-serif;
	font-weight: 500;
	font-size: $size;
	line-height: $line-height;
	@if ($style == italic) {
		font-style: italic;
	} @else {
		font-style: normal;
	}
}

@mixin bd($size, $line-height, $style: normal) {
	font-family: 'museo-sans', sans-serif;
	font-weight: 500;
	font-size: $size;
	line-height: $line-height;
	@if ($style == italic) {
		font-style: italic;
	} @else {
		font-style: normal;
	}
}

@mixin museo--rg($size, $line-height, $style: normal) {
	font-family: 'museo', sans-serif;
	font-weight: 300;
	font-size: $size;
	line-height: $line-height;
	@if ($style == italic) {
		font-style: italic;
	} @else {
		font-style: normal;
	}
}
@mixin museo--bd($size, $line-height, $style: normal) {
	font-family: 'museo', sans-serif;
	font-weight: 700;
	font-size: $size;
	line-height: $line-height;
	@if ($style == italic) {
		font-style: italic;
	} @else {
		font-style: normal;
	}
}