/*--------------------------------------------------------*\
	Newsroom Module
\*--------------------------------------------------------*/
.block--newsroom {

	.newsroom__item {
		display: inline-block;
		
		position: relative;
		border: 1px solid $body;
		margin-bottom: 1.75rem;
		overflow: hidden;
		
		&:after {
			content: "";
			display: block;
		}

		@media (min-width: 1001px) {
			width: calc((100% / 3) - ( 2/3 * 1.75rem));
			// &:nth-child(3n) {margin-right: 0;}
			&:after {
				// padding-bottom: 100%;
				padding-bottom: 85%;
			}
		}

		@include m(1000, 816) {
			width: calc(50% - 0.875rem);
			&:after {
				// padding-bottom: 120%;
				padding-bottom: 66.6%;
			}
		}
		@include m(815) {
			width: 100%;
			&:after {
				padding-bottom: 66.6%;
			}
		}
		@include m(500) {
			&:after {
				padding-bottom: 0;
			}
		}

		.newsroomItem__container {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			@include m(540) {
				position: static;
				height: auto;

			}
		}

		
		.newsroom__image {
			@include bg-center;
			height: 18%;
			border-bottom: 1px solid $body;
			position: relative;

			

			@include m(540) {
				height: 5rem;
			}
			
			.overlay {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				
				background-color: $body;
				opacity: 0;
				transition: opacity 0.25s ease;
			}
		}

		&--news .newsroom__image { background-color: $teal; }
		&--blog .newsroom__image { background-color: $blue; }

		.newsroom__header { 
			padding: 1.5rem 2.25rem;
			@include m(540) {
				padding: 0.5rem 1rem;
			}
		}

		.newsroom__title {
			@include md(1.25rem, 1.35);
			color: $body;
			margin-bottom: 0.25rem;
			transition: color 0.25s ease;
			@include m(540) {
				font-size: 1rem;
			}
		}

		.newsroom__date { 
			@include p();
			color: $body;
			transition: color 0.25s ease; 
		}
		.content {
			padding: 0 1.75rem 1.75rem;
			white-space: pre-wrap;
			overflow: hidden;
			line-height: 1.35;
			color: $body;
			transition: color 0.25s ease;

			@include m(540) {
				padding: 0.5rem 1rem;
			}
		}
		.read-more { color: $blue--slate;}


		&:hover {
			.newsroom__image .overlay { opacity: 0.125;}
		}
		&--news:hover {
			.newsroom__title, .newsroom__date, .content, .read-more {
				color: $teal;
			}
		}
		&--blog:hover  {
			.newsroom__title, .newsroom__date, .content, .read-more {
				color: $blue--slate;
			}
		}
	}
}


/*--------------------------------------------------------*\
	News & Events Module
\*--------------------------------------------------------*/
.news-events__item {
	
	@include flex(1, 0, auto);
	background-color: rgba(white, 0.85);
	color: $blue--slate;
	
	padding: 1.5rem;
	justify-content: space-between;
	text-align: left;
	position: relative;
	max-width: 100%;


	&:after {
		content: "";
		display: block;
		//  padding-bottom: 80%;
		padding-bottom: 85%;
	}

	.content__container {
		@include cover();
		@include flexContainer(row, wrap);

		color: $blue--slate;
		height: 100%;
		padding: 1.5rem;
		transition: color 0.25s ease;
	}

	.date {
		@include md(1.125rem, 1);
		margin-bottom: 1.5rem;
		align-self: flex-start;
	}

	.read-more { 
		@include md(1.125rem, 1); 
		align-self: flex-end;
	}

	h2 { 
		@include rg(1.125rem, 1.5);
		flex-grow: 1;
	}


	@media (min-width: 1001px) {
		width: calc(25% - (3/4 * 1rem));
		&:not(:last-child) {margin-right: 1rem;}
	}
	
	@include m(1000) {
		width: calc(50% - 0.5rem);
		margin-bottom: 1rem;
		&:after {
			padding-bottom: 50%;
		}
		&:not(:nth-child(2n)) { 
			margin-right: 1rem;
		}
	}
	@include m(720) {
		display: block;
		width: 80%;
		&:not(:nth-child(2n)) { 
			margin-right: auto;
		}
		margin: 0 auto 1rem;
	}
	@include m(540) {
		width: calc(100% - 2rem)
	}
	@include m(480) {
		&:after {
			padding-bottom: 66.6%;
		}
	}
	@include m(360) {

		&:after {
			padding-bottom: 100%;
		}
	}


	&:hover {
		.content__container {
			color: $teal;
		}
	}

}