/*--------------------------------------------------------*\
	Keyframes // Animations
\*--------------------------------------------------------*/

@mixin button() {
	@include md(1rem, 1);
	color: #fff;
	text-transform: uppercase;
	cursor: pointer;
	outline: none;
	background-color: $blue--slate;
	padding: 0.75rem 1.75rem;
	transition: all 0.3s ease;


	// .wrapper--education .block--banner & {
	// 	background-color: white;
	// 	color: $orange;
	// 	border: 1px solid $orange;
	// }
	&:hover {
		background-color: white;
		color: $blue--slate;

		.bg--white &,
		.wrapper--single--reports &,
		.wrapper--education .block--banner & { 
			background-color: $orange;
			color: white;
		}
	}
	
	.bg--blue &,
	.bg--blue--slate & {
		background-color: $orange;
		&:hover {
			background-color: white;
			color: $orange;
		}
	}

	@include m(848) {
		font-size: 1rem;
	}

	&.button--teal {
		background-color: $teal;
	}

	+ .button {
		margin-left: 1rem;
	}
}

.button__container {
	padding-top: 0.7rem;
}
.button { 
	@include button();
}