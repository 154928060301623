.score {
	

	&__item {
		display: inline-block;
		border-radius: 100%;
		width: 1.25rem;
		height: 1.25rem;
		background-color: $grey;


		&:not(:last-child) {margin-right: 0.125rem;}

	}
	&--green {
		.score__item--good { background-color: $green; }
	}
	&--yellow {
		.score__item--average { background-color: $yellow; }
	}
	&--red {
		.score__item--bad { background-color: $red }
	}
}