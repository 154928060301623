.tooltip {
	@include p();
	position: absolute;
	font-size: 0.875rem;
	padding: 0.25rem 0.5rem;
	background-color: $body;

	opacity: 0;

	color: white;
	white-space: pre;
	z-index: -1;
	transition: opacity 0.25s ease-in-out;

	&.show { 
		opacity: 1;
		z-index: 11; 
	}
	&:after {
		position: absolute;
		left: 0;
		top: 100%;
		content: "";
		display: block;
		border-bottom: 0.5rem solid transparent;
		border-left: 0.5rem solid $body;
		border-right: 0.5rem solid transparent;  
		border-top: 0.5rem solid $body;
	}
}