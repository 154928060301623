.block--archive--reports {
	.column__container {
		border-bottom: 1px solid $blue--slate;
		padding: 2.5rem 0;

	}

	.content__item--media {
		width: calc(100% / 3);
		max-width: 100%;
		flex-basis: calc(100% / 3);

		@include m(800) {
			width: 50%;
			flex-basis: 50%;
		}
		@include m(600) {
			width: 100%;
			flex-basis: 100%;
			margin-bottom: 1.5rem;
		}
	}
	.content__item--content {
		width: calc(200% / 3);
		max-width: 100%;
		flex-basis: calc(200% / 3);

		@include m(800) {
			width: 50%;
			flex-basis: 50%;
		}

		@include m(600) {
			width: 100%;
			flex-basis: 100%;
		}

		h2 {
			color: $blue--slate;
			font-size: 1.5rem;
			margin-bottom: 1rem;
		}
		.snippet {
			margin-bottom: 1rem;
		}
	}

	.button__container {
		height: 43px;
		margin: 1rem 0;
	}
}