/*--------------------------------------------------------*\
	Accordion Module
\*--------------------------------------------------------*/
.accordion {
	@include flexContainer(row,nowrap);

	@include m(1000) {

		@include flexContainer(row,wrap);
		justify-content: space-between;

	}

	@include m(640) {

		@include flexContainer(column);
	}

	&__item,
	&.animated .accordion__item:not(.accordion__item--active) {
		@include bg-center;
		flex-grow: 0;
		width: calc(100% / 4);
		max-width: 100%;
		display: flex;
		height: 30rem;
		position: relative;

		transition: width 1s $timing;
		text-align: center;
		justify-content: center;
		align-items: center;
		padding: 2rem;
		

		@include m(1000) {
			width: calc(50% - 0.5rem);
			&:not(nth-child(2n)) {margin-right: 1rem;}
		}
		@include m(640) {
			width: 100%;
			margin: 1rem auto;
		}
		h2 { 
			font-weight: 500; 
			text-transform: uppercase;
		}
		&::before {
			content: '';
			@include cover;
			z-index: 0;
			background-color: rgba(#000,0.3);
			transition: all 0.2s ease-in-out;
			mix-blend-mode: multiply;
			backface-visibility: none;
		}

		.content {
			
			max-height: 0;
			margin-top: 1rem; 
			margin: auto;
			opacity: 0;
			overflow: hidden;
			transform: translateY(20%);
			transition: max-height 0.125s ease, opacity 0.35s ease-in-out 0.35s, transform 0.35s ease-in-out 0.125s;
		
			@include m(640) {
				max-height: 50rem;
				opacity: 1;
				transform: translateY(0);
			}

			&__container {
				z-index: 1;
				max-width: calc(100% - 4rem);
				@include m(1800) {
					width: calc(100% - 4rem);
					max-width: calc(100% - 4rem);
				}
				@include m(1440) {
					width:80%;
					max-width: 80%;
				}
				@include m(640) {
					width: 100%;
					max-width: 100%;
				}
			}
		}
	}

	&.animated {

		.accordion__item--active {

			flex-grow: 2;
			width: calc(100% / 2);

			@include m(1000) {
				flex-grow: 0;
				width: calc(50% - 0.5rem);
			}

			&::before {
				background-color: rgba(#000,0.65);
			}

			.content.fadeIn {

				max-height: 20rem;
				transform: translateY(0);
				width: 400px;
				max-width: 400px;
				opacity: 1;

				@include m(1800) {

					width: calc(100% - 4rem);
					max-width: calc(100% - 4rem);
				
				}
				
				@include m(1440) {

					width:80%;
					max-width: 80%;
				
				}
				@include m(640) {

					width: 100%;
					max-width: 100%;
				

				}
				
			}
		}
	}

	.icon__container {
		
		margin-bottom: 0.5rem;

		svg {
			width: 7rem;
			height: 7rem;

			@include m(640) {
				width: 5rem;
				max-width: 5rem;
			}

		}

	}

	+ p {
		margin-right: 1rem;
		@include rg(0.75rem,1);
	}
}