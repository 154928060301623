/*--------------------------------------------------------*\
	Footer
\*--------------------------------------------------------*/
.footer {
	//margin-bottom: 1rem;
	padding: 3rem 0;
	border-right: 1rem solid white;
	border-left: 1rem solid white;
	border-bottom: 1rem solid white;
	background-color: $grey--dark;
	color: white;

	.block__container,
	.footer__container {
		@include flexContainer(row,nowrap);
		//padding: 0 3rem;
		justify-content: space-between;
		align-items: stretch;

		@include m(690) {
			flex-wrap: wrap;
		}
	}

	.footer__contact,
	.footer__social {
		@include flex(1,0,auto);
		margin:0;
		@include m(690) {
			width: 100%;
			flex-basis: 100%;
			order: 2;
			text-align: center;
			&:not(:last-child) {margin-bottom: 1.5rem;}
		}

	}

	.footer__logo {
		@include flexContainer(row);
		@include flex(1, 1, auto);
		align-items: center;
		@include m(690) {
			width: 100%;
			flex-basis: 100%;
			order: 1;
			margin-bottom: 1.5rem;

		}
	}

	.logo__container {
		@include flexContainer(row);
		width: 13.609375rem;
		margin: auto;
		justify-content: center;
		align-item: center;

		position: relative;

		svg {
			height: 4.25rem;
			width: 13.609375rem;
		}
	}

	.footer__contact {
		@include md(0.875rem, 1.25);
		.address {
			padding-left: 0;
			list-style: none;
			margin-bottom: 1rem;
			.address__item {
				&:first-child { display: none; }
			}
		}
	}

	.footer__social {
		@include flexContainer(row);
		align-items: center;
		flex-wrap: wrap;

		.social__list {
			flex-basis: 100%;
			width: 100%;
		}
		a { 
			@include p();
			font-size: 0.875rem;
			color: $white;
			&:hover {
				color: $orange;
			}
		}
		@include m(690) {
			.social__list {margin: auto;}
		}
	}
}