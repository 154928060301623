.nf-form-cont {
	.label-above .nf-field-label {
		margin-bottom: 0;
	}
	
	input:not([type='button']),
	textarea {
		@include p();
		border: 1px solid $body;
		width: 100%;
		padding: 0.5rem;
		transition: all 0.3s ease-in-out;
		margin-bottom: 1rem;
		&:active,&:focus {outline: none;}
	}

	input:not([type='button']) {
		height: 2.25rem;
	}

	.recaptcha-wrap { margin-bottom: 1rem;}
	
	.nf-form-fields-required {display: none;}
	.field-wrap input[type="button"] {
		@include button();
		border: none;
		width: 11rem;
		background-color: $purple;
		&:active,&:focus {outline: none;}
		&:hover {
			background-color: $teal;
			color: white;
		}
	}
}
