/*--------------------------------------------------------*\
	Keyframes // Animations
\*--------------------------------------------------------*/
@keyframes louPulse {
	0% { transform: scale(1); }
	50% { transform: scale(1.25); }
	1000% { transform: scale(1); }
}

@keyframes appear {
	0% { opacity: 0; }
	100% { opacity: 1; }
}