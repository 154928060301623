/*--------------------------------------------------------*\
	Search Results
\*--------------------------------------------------------*/

.search-term {
	font-size: 1.25rem;
}
.block--search {
	padding-top:0;
	.column__container + .column__container {
		margin-top: 2rem;
	}
	h2 {
		color: $teal;
		font-size: 1.25rem;
	}
	.search__item {
		position: relative;
		&:hover {
			h2 { color: $orange; }
		}
	}
}