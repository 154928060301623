
/*--------------------------------------------------------*\
	Variables
\*--------------------------------------------------------*/

// $content__container: 100%;

/*--------------------------------------------------------*\
	Content - Main Styles
\*--------------------------------------------------------*/
.column__container {
	@include flexContainer(row, wrap);
	align-items: stretch;
	+ .column__container {
		margin-top: 5rem;
		@include m(845) {
			margin-top: 3.5rem;
		}
		@include m(720) {
			margin-top: 2rem;
		}
		@include m(500) {
			margin-top: 1.5rem;
		}
	}
}
.content {
	margin: 0 auto;
	max-width: 100%;
	
	@include p();
	// Content Typography
	p { margin-bottom: 1.5rem;}
	strong {font-weight: 700;}
	em {font-style: italic;}

	// Lists
	ul {list-style: disc outside;}
	ol {list-style: decimal outside;}
	ul,ol {
		margin-bottom: 1.5rem;
		padding-left: 2rem;
	}

	// Quotes
	blockquote {}
	q {}

	h1, h2, h3, h4, h5, p, ul, ol, div {
		&:only-child, &:last-child { margin-bottom: 0; }
	}

	// from Wordpress Codex, https://codex.wordpress.org/Styling_Images_in_Posts_and_Pages
	img.alignright {float:right; margin:0 0 1em 1em}
	img.alignleft {float:left; margin:0 1em 1em 0}
	img.aligncenter {display: block; margin-left: auto; margin-right: auto}
	a img.alignright {float:right; margin:0 0 1em 1em}
	a img.alignleft {float:left; margin:0 1em 1em 0}
	a img.aligncenter {display: block; margin-left: auto; margin-right: auto}
}

h1 {@include h1();}
h2 {@include h2();}
h3 {@include h3();}
h4 {@include h4();}
h5 {@include h5();}
h6 {@include h6();}

/*--------------------------------------------------------*\
	Content - Columns
\*--------------------------------------------------------*/
.content__item {
	&--content {
		padding: 0 2rem 0 0;
		@include m(600) {
			padding: 0;
		}
	}
	&--full-width {
		@include flex(1,0,100%);
		width: 100%;
		max-width: 100%;
		padding-right: 0;

		&:not(:last-child) {
			margin-bottom: 2rem;
		}
	}
	&--half-width {
		@include flex(1,0,50%);
		width: 50%;
		max-width: 50%;

		@include m(900) {
			width: 100%;
			max-width: 100%;
			flex-basis: 100%;
			&:first-child { margin-bottom: 2.5rem;}
		}
	}

	&--two-thirds {
		// @include flex(1,0,calc(200% / 3));
		@include flex(1,0,calc( (200% / 3) - 2rem ));
		// width: calc(200% / 3);
		width: calc( (200% / 3) - 2rem );

		max-width: 100%;
		&:first-child {
			padding-right: 3rem;
		}
		&:nth-child(2) {
			padding-left: 3rem;
		}
		@include m(900) {
			width: 100%;
			max-width: 100%;
			flex-basis: 100%;
			&:not(:last-of-type) {margin-bottom: 2.5rem;}
		}
	}
	&--one-third {

		@include flex(1,0,calc(100% / 3));
		width: calc(100% / 3);
		max-width: calc(100% / 3);

		@include m(900) {
			width: 100%;
			max-width: 100%;
			flex-basis: 100%;
			&:not(:last-of-type) {margin-bottom: 2.5rem;}
		}
	}

	&--media {
		header { margin-bottom: 1.5rem; width: 100%;}
		display: flex;
		width: 100%;
		height: 100%;

		.media {
			@include flexContainer(column, no wrap);
			justify-content: flex-start;
			align-items: center;
			width: 100%;
			height: 100%;

			.button__container {
				height: 43px;
				margin-top: 2rem;
				align-self: flex-start;
			}
		}

		.svg__container, svg {
			width: 100%;
			height: 100%;
		}
	}

	&--media + .content__item--content {
		padding-left: 2rem;
	}
}

#map__container {position:relative;}