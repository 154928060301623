/*--------------------------------------------------------*\
	Banners
\*--------------------------------------------------------*/
.block--banner {
	height: auto;
	min-height: 27rem;
	width: 100%;
	margin-top: 7rem;
	
	@include m(1280) {
		min-height: 25rem;
	}

	@include m(1000) {
		min-height: 22rem;
	}
	@include m(550){
		min-height: 18rem;
		margin-top: 5rem;
	}

	.block__container {
		@include center;
	}

	.content {
		p {
			font-size: 1.25rem;
		}
		.button__container + .button__container {
			@include flex(1,0, auto);
			margin-left: 1.5rem;
		}
	}
	
}

// Remove Line Breaks on mobile
@include m(480) {
	.block--banner h1 br,
	.block--banner h2 br {
		content: "";
		&:after {
			content: "\00a0";
		}
	}
}