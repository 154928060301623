/*--------------------------------------------------------*\
	Sliders Module
\*--------------------------------------------------------*/
	/* big buttons, no circle */
.flickity-prev-next-button {
  width: 4rem;
  height: 4rem;
  background: transparent;
  border: none;
  position: absolute;
  @include v-center;
  cursor: pointer;
}

.flickity-prev-next-button:hover {
  background: transparent;
}
/* arrow color */
.flickity-prev-next-button svg {
	transition: fill 0.2s ease;
	fill: white;
	&:hover {
		fill: $orange;
	}

	.bg--white & { 
		fill: $grey; 
		&:hover {
			fill: $blue--slate;
		}
	}

	&:active {outline: none;}
}
.flickity-prev-next-button.no-svg {
  color: white;
}
/* hide disabled button */
.flickity-prev-next-button:disabled {
  display: none;
}
/* position outside */
.flickity-prev-next-button.previous {
  left: -56px;
  @include m(560) {
  	left: -44px;
  }
  @include m(320) {
  	left: -30px;
  }
}
.flickity-prev-next-button.next {
  right: -56px;
  @include m(560) {
  	right: -44px;
  }
  @include m(320) {
  	right: -30px;
  }
}

.flickity-page-dots {
	.dot {
		width: 12px;
		height: 12px;
		background-color: $grey;
		border-radius: 50%;
		margin-right: 0.5rem;
		display: inline-block;
		cursor: pointer;
		&.is-selected,
		&:hover {
			background-color: $blue--slate;
		}
	}
}


.slider {
	&__container {
		width: 100%;
		position: relative;
		&:active, &:focus {
			outline: none;
		}		

		.flickity-viewport,
		.flickity-slider {
			width: 100%;
			height:auto;
		}

		.flickity-viewport {
			overflow: hidden;
		}
	}

	&__item {
		@include bg-center;
		display: block;
		width: calc(100% / 3);
		margin-right: 0;

		@include m(9999, 1441) {
			background-position: top center;
		}
		@include m(1441, 849) {
			background-position: center center;
		}


		&--tweets {
			width: 100%;
			text-align: center;
			.content {
				max-width: 54rem;
				font-size: 1.25rem;
			}
		}

		&--reports {
			width: calc(25% - (3/4 * 1rem));
			border: 2px solid $grey;
			position: relative;
			box-sizing: border-box;
			margin-right: 1rem;
			display: inline-block;

			@include m(1024) {
				width: calc((100% / 3) - (2/3 * 1rem));
			}

			@include m(820) {
				width: calc(50% - 0.5rem);
			}
			@include m(540) {
				display: block;
				width: 100%;
				// margin-left: 12.5%;
				// margin-right:12.5%;
			}
			@include m(320) {
				width: 80%;
				margin-left: 10%;
				margin-right: 10%;
			}


			&:after {
				content: "";
				display: block;
				padding-bottom: 100%;
			}

			.sliderItem__container {
				@include center;
				width: 90%;
				position: absolute;
			}

			.slider__content {
				@include md(1.5rem, 1.25);
				text-align: center;
				color: $blue--slate;
			}

			.report__overlay {
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
				max-height: 0;
				z-index: 9;
				background-color: $orange;
				transition: max-height 0.25s ease;
				overflow: hidden;
				.year {
					@include center;
					@include md(1.75rem, 1);
					color: white;
					text-align: center;

				}
			}

			&:hover {
				.report__overlay {
					max-height: 100%;
				}
			}
		}

		&--quotes {
			width: 100%;
			transition: color 0.25s ease;

		
			blockquote {
				@include rg(1.35rem, 1.5);
				padding-left: 3.125rem;

				@include m(575) {
					font-size: 1.25rem;
				}
				@include m(480) {
					padding-left: 1.85rem;
					font-size: 1rem;
				}
			}
			svg {
				width: 4rem;
				height: 4rem;
				@include m(575) {
					width: 2.5rem;
					height: 2.5rem;
				}
				@include m(480) {
					width: 1.75rem;
					height: 1.75rem;
					position: absolute;
				}
			}
			.citation {
				font-weight: 500;
				display: block;
				margin: 1rem 0;
				color: $blue--slate;
				transition: color 0.25s ease;

				@include m(480) {
					margin: 0.5rem 0 1rem;
				}
			}

			&:hover {
				color: $teal;
				.citation {
					color: $teal;
				}
			}
			
		}
		// .sliderItem__container {
		// 	@extend .cover;
		// }
		// &:after {
		// 	content: "";
		// 	display: block;
		// 	padding-bottom: 100%;
		// }
	}

	&__item--tweets {
		svg {
			width: 1.5rem;
			height: 1.5rem;
			path {
				fill: white;
			}
		}
	}

	&--quotes .flickity-page-dots {
		padding-left: 3.125rem;
		margin-top: 1rem;
	}

	&--tweets .flickity-prev-next-button {
		@include m(414) {
			display: none;
		}
	}
}