
.overlay__close {
	min-height: 18px;
	width: 25px;
	position: absolute;
	right: 1rem;
	top: 1.25rem;
	cursor: pointer;
	.close__icon {
		display: block;
		background-color: white;
		height: 3px;
		width: 100%;
		// transform: rotate(0);
		// &:not(:last-child) {margin: 0 0 2px;}
		transform-origin: center;
		transition: all 0.2s ease-in-out;

		&:first-child {
			transform: rotate(45deg) translate(2px,3px);
		}
		&:last-child {
			transform: rotate(-45deg);
		}
	}

	&:hover .close__icon {
		background-color: #9fdfe4;
	}
}

.overlay__header {
	// background-image: url('/build/img/menu.jpg');
	// @include bg-center;
	display: flex;
	flex-flow: column wrap;
	background-color: $grey--dark;
	height: 100%;
	width: auto;
	position: fixed;
	right: 0;
	top: 0;
	min-width: 343px;
	transform: translateX(100%);
	transition: all 0.25s ease-in-out;
	z-index: 1000;

	&--active {
		transform: translateX(0);
	}
	
	header {
		height: 16.6%;
	}

	footer { 
		padding: 1rem 0 1rem;
		margin-left: 2.5rem;

		.social__list {
			padding-left: 0;
			li { 
				display: inline;
				position: relative;

				&:not(:last-child) {
					margin-right: 0.75rem;
				}
				&:hover {
					svg path {fill: #9fdfe4; }
				}
			}
			svg {
				height: 1.5rem;
				width: 1.5rem;


				path {fill: white;}
			}
		}
	}
}

.overlay__nav {
	margin-left: 2.5rem;
	flex-grow: 1;

	li {
		@include bd(1.25rem, 1);
		font-weight: 500;
		&:not(:last-child) { margin-bottom: 1rem;}
		a { 
			color: white;
			&:hover {
				//color: $teal;
				color: #9fdfe4;
			}
		}
		&.submenu--open { color: #9fdfe4; }

		.sub-menu {
			padding-left: 1rem;
			overflow: hidden;
			max-height: 0;
			transition: max-height 0.35s ease;

			&.active {
				margin-top: 1rem;
				max-height: 100%;
			}
		}

	}

	.nav--overlay--mobile {
		display: none;
		@include m(800) {
			display: block;
			margin-bottom: 1rem;
		}
	}
}

