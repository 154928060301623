/*--------------------------------------------------------*\
	Modules SCSS Index
\*--------------------------------------------------------*/

@import 
"sliders",
"banner",
"news",
"team",
"partners",
"tabs",
"accordion",
"commResources",
"factorLoops",
"single",
"latestPosts",
"contact",
"reports",
"sources";
