.block--latest-posts {
	padding: 3rem 0;
	.content__item {
		position: relative;
	
		h3 { 
			color: $teal;
			margin-bottom: 0;
		}

		&:hover {
			h3 { color: $orange; }
		}
	}
}