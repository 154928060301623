/*--------------------------------------------------------*\
	Header
\*--------------------------------------------------------*/
.header {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 7rem;
	background-color: white;

	z-index: 999;

	&--scrolled {
		position: fixed;
		background-color: rgba(white, 0.85);
	}


	.header__container {
		@include flexContainer(row,nowrap);
		justify-content: space-between;
		align-items: center;
		height: 7rem;
		padding: 0 3rem;

		@include m(550) {
			height: 5rem;
			padding: 0 1.5rem;
		}
	}
	.header__logo { 
		width: 12.8125rem; 
	}
	.logo__container {
		position: relative;
		svg {
			height: 3.5rem;
			width: 12.8125rem;
			@include m(550){
				height: 3rem;
			}
		}
	}


	@include m(550) {
		height: 5rem;
	}

}

.overlay__header + .block {
	margin-top: 7rem;
	@include m(550) {
		margin-top: 5rem;
	}
}