

.block--banner--single {
	height: 19rem;
}
.block--single {
	@include m(600) {
		padding: 0;
	}
	@include m(420) {
		.block__container {
			padding: 0.5rem;
		}
	}
	.content--offset {
		margin-top:-10rem;
	}
	article {
		padding: 3rem;
		max-width: 66rem;
		p { @include p();}

		@include m(640) {
			padding: 1.5rem;
		}
		@include m(600) {
			// font-size: 1rem;
			// display: inline-block;
			// width: 50%;
		}
	}
	.header--article {
		margin-bottom: 1.5rem;

		.date--article {
			@include md(1.25rem, 1.35);
			color: $blue--slate;
			display: block;
			margin-bottom: 0.125rem;
			@include m(600) {
				font-size: 1rem;
			}
		}
	}
}

.wrapper--single--reports {
	.block:not(.block--report-cta) {
		&:nth-child(even) {
			background-color: $grey--light;
			margin-bottom: 1rem;
		}
	}
	.block--report-cta {
		padding: 1.5rem 0 2.5rem;
		.block__container { height: 40px;}
		text-align: center;
		.button--teal {
			width: 16rem!important;
		}
	}
}