.header__nav {
	@include flex(1,0,auto);
	@include flexContainer(row);
	align-items: center;
	.nav--main {
		@include md(1rem, 1);
		text-transform: uppercase;

		@include m(845) {
			display: none;
		}
		li {

			display:inline-block;
			margin-right: 1.5rem;
			a { 
				color: $body;
				transition: all 0.2s ease;
			}

			a:hover {
				color: $blue--slate;
			}
		}
	}
}

.overlay__open {
	display: flex;
	flex-flow: column wrap;
	justify-content: space-between;
	min-height: 18px;
	width: 25px;
	cursor: pointer;

	@include m(480) {
		width: 30px;
	}

	//background-color: $red;
	.open__icon {
		background-color: $body;
		height: 3px;
		width: 100%;
		transform: rotate(0);
		&:not(:last-child) {margin: 0 0 2px;}
		transform-origin: center;
		transition: all 0.2s ease-in-out;
	}

	&:hover {
		.open__icon {
			background-color: $blue--slate;
			&:not(:last-child) {margin: 1px 0 2px;}
			&:last-child { margin-top: 1px; }
		}
	}

	&--active {	
		z-index: 1001;

		.open__icon {
			background-color: white;
			&:first-child, &:last-child {
				opacity: 0;
			}
			&:nth-child(2) {
				transform: rotate(45deg) translate(4px,3px);
			}
			&:nth-child(3) {
				transform: rotate(-45deg);
			}
		}
	}
	
}