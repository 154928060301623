/*--------------------------------------------------------*\
	Isotopes
\*--------------------------------------------------------*/
.iso__filtersContainer {
	text-align: center;
	padding: 3.625rem 0;
	.iso--filter { 
		@include md(1.25rem, 1);
		display: inline-block;
		cursor: pointer;

		&:hover, &.filter--active {
			color: $blue--slate;
		}

		&:not(:last-child) {
			margin-right: 1rem;
			&:after {
				content: "|";
				display: inline-block;
				margin-left: 1rem;
				color: $body;
			}
		}
	}
}