/*--------------------------------------------------------*\
	Sources Module
\*--------------------------------------------------------*/
.block.block--sources-nav {
	padding: 0;

	.alphaLink__list {
		list-style: none;
		padding-left: 0;

		.alphaLink__item {
			display: inline-block;
			@include rg(1.25rem, 1);
			margin-right: 1rem;

			a { 
				color: $teal; 

				&:hover {
					color: $orange;
				}
			}
		}
	}
}

.block--sources {
	padding-top: 3rem;
	.sources__container {
		margin-bottom: 3rem;
	}
	p {
		@include rg(1.25rem, 1);
		color: $teal;
		margin-bottom: 0.5rem;
	}
	.sources__list {
		padding-left: 0;
		list-style: none;
		.sources__item {

		}
	}
}