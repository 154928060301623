.block--commResources .block__container .content {
	max-width: 42rem;
}

.commResources__container {
	@include flexContainer(row,wrap);
	width: 100%;
	margin-top: 2rem;

	.commResource__item {
		@include flex(1,0,auto);
		width: calc(25% - (1rem * 3/4));
		max-width: 100%;
		position: relative;

		@include m(845) {
			width: 50%;
		}
		@include m(525) {
			width: 100%;
			&:not(:last-child) {
				margin-bottom: 1.5rem;
			}
		}


		.content {
			text-align: left;
			font-size: 1rem;
		}
		
		.commResource__title {
			font-size: 1.375rem;
			margin-bottom: 1rem;
			color: $teal;
			text-align: left;
			transition: color 0.25s ease;
		}

		&:hover {
			.commResource__title {
				color: $orange;
			}
		}
	}
}