#peer-map {
	.column__container {
		align-items: center;
	}
	.content__item--full-width:not(:last-child) {
		margin-bottom: 0;
	}
}
.customMap__container {
	position: relative;
	width: 100%;
	&:after {
		content: "";
		display: block;
		padding-bottom: 100%;
	}
}
.custom__map {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}