/*--------------------------------------------------------*\
	Typography ==> Main
\*--------------------------------------------------------*/

@mixin h1() {
	@include rg(2.875rem, 1);
	margin-bottom: 1.125rem;
	&:only-child { margin-bottom: 0; }
	@include m(600) {
		font-size: 2.5rem;
	}
	@include m(500) {
		font-size: 2.25rem;
	}
}
@mixin h2() {
	@include rg(1.625rem, 1.45);
}
@mixin h3() {
	@include museo--rg(1.375rem, 1.125);
	margin-bottom: 1.125rem;
	&:only-child { margin-bottom: 0; }
}
@mixin h4() {
	@include bd(1.25rem, 1.125);
}
@mixin h5() {
	@include rg(1.125rem, 1.125);
}
@mixin h6() {
	@include rg(1rem, 1.125);
}
@mixin p() {
	@include rg(1rem, 1.5);
}

/*--------------------------------------------------------*\
	Typography ==> Banner
\*--------------------------------------------------------*/

@mixin title--banner() {
	@include bd(4rem, 0.9325);
}