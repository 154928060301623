@import 
'tooltips',
'customMaps';



circle[fill='#F27E2C'] {
	//opacity: 0;
	&.show {
		animation: appear 0.25s forwards;
		@for $i from 1 through 4 {
			&.show-#{$i} {
				animation-delay: $i * 0.25s;
			}
		}
	}
}


circle[fill='none'] {
	transform-origin: center;
	animation: louPulse 1.5s infinite;

	.browser--firefox & {
		animation: none;
	}
}