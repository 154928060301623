/*--------------------------------------------------------*\
	Search
\*--------------------------------------------------------*/
.search__container {
	@include flexContainer(row);
	padding: 1rem;
	align-items: center;
	.header & { 
		padding: 0;
		margin-right: 0.75rem; 
		@include m(540) {
			display: none;
		}
	}
}

.search__icon,
.search__button {
	cursor: pointer;

	svg {
		width: 1.25rem;
		height: 1.25rem;
		stroke-width: 3px;
		stroke: $body;

		path { 
			fill: $body; 
		}
	}

	&:hover {
		svg {
			stroke-width: 3px;
			stroke: $blue--slate;
			path { 
				fill: $blue--slate;
			}
		}
	}
}

.search__form {
	width: 100%;
	max-width: 30rem;
	margin: 0 auto;

	form {
		display: block; 
		margin: 1.5rem auto 0;
		position: relative;
		width: 100%;
		max-width: 30rem;
	}
	.header & form {
		width: auto;
		margin: auto;
		text-align: right; 
	}

	input.search__field {
		@include p();
		border: 1px solid $body;
		width: 100%;
		max-width: 30rem;
		padding: 1rem 1.5rem;
		.browser--firefox & {
			padding: 0 1.5rem;
		}
		height: 2.25rem;
		transition: all 0.3s ease-in-out;
		margin: 1rem auto;

		.header & {
			overflow: hidden;
			opacity: 0;
			height: 2rem;
			width: auto;
			max-width: 0;
			padding: 0.25rem 0.5rem;
			margin: 0 0 0 0.75rem;
		}
		&:focus {
			outline: none;
		}
	}

	&.search-form--active input.search__field {
		opacity: 1;
		width: 15rem;
		max-width: 100%;
	}

	.header & .search__button svg,
	input[type='submit'] { display: none; }


	label.search__button {
		height: 1.5rem;
		width: 1.5rem;
		position: absolute;
		right: 1rem;
		top: 1.5rem;
	}

}