/*--------------------------------------------------------*\
	Carousels
\*--------------------------------------------------------*/
// Carousel Block
.block--carousel { }

// Carousel
#carousel--module { }

.carousel__item { 

	.carousel-item__container {	}

}

// Carousel Navigation
#nav--carousel {

	.carousel__prev { }

	.carousel__next { }
	
}
