/*--------------------------------------------------------*\
	Subnavigation for Driver Landing Pages
\*--------------------------------------------------------*/
.driver__subnav {
	width: 100%;
	margin: 0 auto;

	@include m(575) {
		padding: 0 1rem;
	}

	.subnav__container {
		@include flexContainer(row);
		justify-content: center;

		&.affixed {
			position: fixed;
			width: 100%;
			z-index: 999;

			.subnav__item {background-color: rgba(white, 0.85);}
		}
		.subnav__item {
			@include flexContainer(row);
			@include flex(1, 0, auto);
			@include md(1.25rem, 1);

			justify-content: center;
			align-items: center;
			padding: 1rem 0;
			text-align: center;
			width: calc(25% - (3/4 * 1rem));
			max-width: calc(25% - (3/4 * 1rem));
			position: relative;

			transition: background-color 0.25s ease,
				color 0.25s ease;
			
			@include m(900) {
				font-size: 1rem;
			}
			@include m(575) {
				//font-size: 1rem;
				width: 50%;
				max-width: 50%;
				flex-basis: 50%;
			}

			&:not(:last-child) {margin-right: 1rem;}

			&--education {
				color: $orange;
				border: 1px solid $orange;
				&:hover {
					background-color: $orange;
					color: white;
				}
			}
			&--jobs {
				color: $teal;
				border: 1px solid $teal;
				&:hover {
					background-color: $teal;
					color: white;
				}
			}
			&--health {
				color: $blue;
				border: 1px solid $blue;
				&:hover {
					background-color: $blue;
					color: white;
				}
			}
			&--quality-of-place {
				color: $purple;
				border: 1px solid $purple;
				&:hover {
					background-color: $purple;
					color: white;
				}
			}
			
		}
	}
}