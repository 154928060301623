/*--------------------------------------------------------*\
	Modal
\*--------------------------------------------------------*/
span.modal__trigger {
	cursor: pointer;
}
.modal {
	position: fixed;
	transform: translate(-50%,-30%);
	left: 50%;
	top: 50%;
	max-width: 56rem;
	width: calc(100% - 7rem);
	opacity: 0;
	z-index: 999999;
	visibility: hidden;
	transition: all 0.25s ease;
	background-color: #fff;
	padding: 2rem;
	@include m(768) {
		width: 95%;
	}
	@include m(400) {
		padding: 1.5rem;
	}

	iframe, object, embed {
		display: none;
	}

	&--visible {
		visibility: visible;
		transform: translate(-50%, -50%);
		opacity: 1;
		iframe, object, embed {
			display: block;
			margin: 0 auto;
		}
	}

	&--large {
		transform: translate3d(-50%, 0, 0);
		position: absolute;
	}

	&__content {
		width: 100%;
	}

	&--video {
		padding: 0;
	}
}
 
.modal__overlay {
	visibility: hidden;
	display: block;
	z-index: 9999;
	opacity: 0;
	background: rgba(255,255,255, 0.8);
	position: fixed;
	overflow: auto;
	overflow-x: hidden;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transition: all 0.15s ease-in;

	&--active {
		opacity: 1;
		visibility: visible;
	}
}
.modal--border {
	border: 1px solid;
}
.modal__close {
	position: absolute;
	top: 0.5rem;
	right: -2.5rem;
	width: 1.5rem;
	height: 1.5rem;
	transform: rotate(45deg);
	cursor: pointer;
	transition: transform 1s $timing;
	@include m(768) {
		right: 0.5rem;
		top: -2.5rem;
	}

	&::before, &::after {
		content: '';
		position: absolute;
		background: $body;
		width: 1.5rem;
		height: 0.125rem;
		display: block;
	}
	&::before {
		transform: rotate(90deg);
		left: 0;
		top: 0.75rem;
	}
	&::after { 
		left: 0;
		top: 0.75rem;
	}

	&:hover {
		transform: translateY(-0.25rem) rotate(45deg);
	}
}